<template>
	<div class="about">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kolmevalkoistakiveahiekalla.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Tietoa meistä</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<p>Urapurje on perustettu vuonna 2004 palvelemaan varsinaissuomalaisia korkeakoulutettuja, esimiehiä ja asiantuntijoita työurakysymyksissä. Teemme yhteistyötä monien tahojen kanssa: Toimintaamme ovat vuosien varrella tukeneet Turun kaupunki, Varsinais-Suomen ELY-keskus, Salon kaupunki, Akavan Erityisalat, Esimiehet ja Asiantuntijat YTY, Ingenjörerna i Finland DIFF, Insinööriliitto, Luonnon-, ympäristö- ja metsätieteilijöiden Loimu, Myynnin ja markkinoinnin ammattilaiset MMA, Opetusalan Ammattijärjestö OAJ, Suomen Ekonomit, Tekniikan Akateemiset TEK, Tekniska Föreningen i Finland, Tradenomit ja Yhteiskunta-alan korkeakoulutetut. </p>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container">
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://akava.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoakava.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.akavanerityisalat.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoakavanerityisalat.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://diff.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logodiff.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.ekonomit.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoekonomit.webp" ></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://ilry.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoinsinooriliitto.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.juko.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logojuko.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.loimu.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoloimu.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://mma.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logomma.webp" ></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.oaj.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logooaj.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://salo.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logosalo.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://tek.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logotek.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://tfif.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logotfif.webp" ></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://tral.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logotral.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.turku.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoturku.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.yhteiskunta-ala.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoyka.webp" ></a>
				</div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.ytn.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoytn.webp" ></a>
				</div>
				<div class="col"></div>
			</div>
			<div class="row align-items-center">
				<div class="col"></div>
				<div class="col-sm-2" style="padding-bottom: 25px;">
					<a href="https://www.yty.fi/"><img class="img-fluid fluid-grow logo" alt="" src="../assets/kumppanit/logoyty.webp" ></a>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px; margin-bottom: 25px;">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Rekisteriseloste</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<p>Varsinais-Suomen omatoimisen työllistymisen tuki Votty ry / Urapurje 18.6.2020</p>
					<p>Urapurje on perustettu vuonna 2004 palvelemaan varsinaissuomalaisia korkeakoulutettuja, esimiehiä ja asiantuntijoita työurakysymyksissä. Teemme yhteistyötä monien tahojen kanssa: Toimintaamme ovat vuosien varrella tukeneet Turun kaupunki, Varsinais-Suomen ELY-keskus, Salon kaupunki, Akavan Erityisalat, Esimiehet ja Asiantuntijat YTY, Ingenjörerna i Finland DIFF, Insinööriliitto, Luonnon-, ympäristö- ja metsätieteilijöiden Loimu, Myynnin ja markkinoinnin ammattilaiset MMA, Opetusalan Ammattijärjestö OAJ, Suomen Ekonomit, Tekniikan Akateemiset TEK, Tekniska Föreningen i Finland, Tradenomit ja Yhteiskunta-alan korkeakoulutetut. </p>
					<ol><li>Tietokannan ylläpitäjä Varsinais-Suomen omatoimisen työllistymisen tuki Votty Ry Y-tunnus 2785393-5 Lemminkäisenkatu 14-18A, 20520 TURKU <br></li><li>Tietokanta-asioista vastaava henkilö Mia Adolfsson Gsm: +358 40 574 66 68, e-mail: mia.adolfsson@tek.fi <br></li><li>Tietokannan nimi Ilmoittautuminen Urapurjeen Vinssi-pienryhmiin</li><li>Tietokannan käyttötarkoitus ja oikeusperusta Tietoja käytetään Vinssi-ryhmiin ja Urapurje-palvelun osallistumista koskevien asioiden viestintään. Henkilötietojen käsittelyssä noudatetaan EU:n yleistä tietosuoja-asetusta sekä kansallista tietosuojalainsäädäntöä.</li><li>Tietokannan sisältö Tietokantaan tallennetaan seuraavia henkilötietoja: rekisteröidyn nimi, sähköpostisoite, osoite ja mahdollinen puhelinnumero. Lisäksi tietokantaan on tallennettu tietoa osallistujan koulutuksesta, ammattiliittojäsenyydestä sekä viimeisimmästä työsuhteesta sekä työttömyyden kestosta. </li><li>Säännönmukaiset tietolähteet Tietokantaan tallennetaan tietoja rekisteröidyn oman ilmoituksen perusteella.</li><li>Tietojen luovuttaminen Urapurje luovuttaa pyydettäessä Vinssi-ryhmiin osallistuneiden henkilötietoja niille työvoimahallinnon viranomaisille, joilta Vinssi-ryhmien rahoitus saadaan.</li></ol>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px; margin-bottom: 25px;">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-6">
					<h2>Saatavuusseloste</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-6">
					<p>Tämä on organisaation Varsinais-Suomen omatoimisen työllistymisen tuki Votty ry / Urapurje saavutettavuusseloste Tämä saavutettavuusseloste koskee palvelua www.urapurje.fi . ja on laadittu / päivitetty 18.06.2020. Olemme arvioineet palvelun saavutettavuuden itse</p>

					<h3>Digipalvelun saavutettavuuden tila</h3>
					<p>Täyttää saavutettavuusvaatimukset osittain.</p>

					<h3>Ei saavutettava sisältö</h3>
					<p>Verkkosivusto ei ole vielä kaikilta osin vaatimusten mukainen. Korjataan sitä mukaa, kun tunnistamme puutteet. Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille ja teemme parhaamme puutteen korjaamiseksi Ilmoita puutteesta sähköpostilla info@urapurje.fi</p>

					<h3>Valvontaviranomainen</h3>
					<p>Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 30 päivää. Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan neljän viikon aikana, voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten ilmoituksen voi tehdä ja miten asia käsitellään.</p>

					<p>Valvontaviranomaisen yhteystiedot:<br>
					Etelä-Suomen aluehallintovirasto<br>
					Saavutettavuuden valvonnan yksikkö<br>
					www.saavutettavuusvaatimukset.fi<br>
					saavutettavuus(at)avi.fi<br>
					puhelinnumero vaihde 0295 016 000</p>


				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import Social from '../components/Social.vue'

export default {
name: 'Tietoa',
components: {
    Social
  }
}
</script>

<style type="text/css">
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>